<template>
	<Button label="开始探索" class="p-button-raised p-button-yellow"
	@click="comfirm()" ></Button>
    <Dialog :showHeader="false" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
      <div class="mt-4 text-center ">
        <h4 class="mt-4">
            正在探索地图
        </h4>
        <p>请稍等...</p>
      </div>
    </Dialog>
    
    <Dialog :showHeader="false" v-model:visible="isShowResult" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <div class="text-right mt-3 defult-dialog-header">
            <span class="p-dialog-header-close-icon pi pi-times" @click="closeResult"></span>
        </div>
      <div class="mt-4 text-center ">
          
        <template v-if="ResultData[1].rollNum.toNumber()<=ResultData[2].need.toNumber()">
          <Button label="探索地图成功" class="p-button-raised p-button-success w_10"></Button>
          <h4 class="mt-4"><span class="text-warning"> {{ Number(ethers.utils.formatEther(ResultData[5].reward.toString()))}} {{tokenName}}</span></h4>
        </template>
         <template v-else>
           <Button label="探索地图失败" class="p-button-raised p-button-danger w_10"></Button>
         </template>
        
        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-6">
                <Button :label="'幸运号'+ ResultData[1].rollNum.toNumber()" 
                class="mr-2 p-button-raised p-button-secondary w_10"></Button>
            </div>
            <div class="col-12 lg:col-6 xl:col-6">
                 <Button :label="'需要'+ResultData[2].need.toNumber()" 
                class="mr-2 p-button-raised p-button-secondary w_10" ></Button>
            </div>
        </div>
        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-6">
                <Button :label="'经验'+ResultData[3].epNum.toNumber()" 
                class="mr-2 p-button-raised p-button-secondary w_10"></Button>
            </div>
            <div class="col-12 lg:col-6 xl:col-6">
                 <Button :label="'等级'+ResultData[4].level.toNumber()" 
                class="mr-2 p-button-raised p-button-secondary w_10" ></Button>
            </div>
        </div>

        <a :href="'https://testnet.bscscan.com/tx/'+ResultData[6].txid" target="_blank">查看交易ID</a>
        
      </div>
    </Dialog>

   
</template>

<script>
import { useStore } from "vuex";
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';

import {challengeBoss} from "../../wallet/LogicBoss";
export default defineComponent({
    name:"challengeBossModal",
    emits: ["getMapList"],
    props: [
        'roleInfo',
        'bossLevel'
    ],
    setup(props,{emit}) {
        const store = useStore();
        const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let address=ref("");
        const bossLevel=ref(props.bossLevel);
        let isShowResult=ref(false);
        let ResultData=ref(false)
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const ModalOpen=()=>{
            
             display.value=true
        }
        const comfirm=()=>{
            display.value=true;
            console.log("确认打怪的角色叫",roleInfo.value.role.username);
            challengeBoss(bossLevel.value, (roleInfo.value.role.itemId).toNumber()).then(res => {
                console.log("获取打怪后的数据", res);
                //getRoleListEvent();
                display.value=false;
                isShowResult.value=true;
                ResultData.value=res;
               
               
            })
          
             
        }

       

      
        const closeResult=()=>{
            isShowResult.value=false;
            store.dispatch('fetchRoleListData').then((res) => {
                emit('getMapList')
            })
        }

        return {
            ModalOpen,
            address,
            comfirm,
            display,
            isShowResult,
            closeResult,
            ResultData
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
