<template>
	<div class="grid" v-if="mapList">
		<div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<div class="font-medium text-lg mb-4">
							当前角色战力
						</div>
						<!-- <span class="block text-sm font-medium mb-3">
                            基于您现在可用的最小体力值
                        </span> -->

						<div class="text-green-400 font-medium text-xl" v-if="$store.getters.getRoleListData.length>0">
							{{$store.getters.getRoleListData[$store.getters.getChoseRoleId].minePower}}

						</div>
						<div class="text-green-400 font-medium text-xl" v-else>
							0
						</div>
					</div>

				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>

		<div class="col-12 lg:col-6 xl:col-6">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center align-items-center ">
					<div class="content">
						<div class="font-medium text-lg mb-4">
							当前挑战的最高地图等级
						</div>
						<!-- <span class="block text-sm font-medium mb-3">
                            基于现在可以进入伤害的地图
                        </span> -->
						<div class="text-pink-500 font-medium text-xl">
							{{maxCanMapLevel}}
						</div>
					</div>

				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
	</div>
	<div class="grid mt-4">
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="content_title mb-4">
				当前拥有的所有角色
			</div>
		</div>
	</div>
	<div class="grid">
		<div class="col-12 text-center">
			<selectRoleModal type="mapPage" />
		</div>
	</div>
	<template v-if="mapList">
		<div class="grid mt-4" v-if="$store.getters.getRoleListData.length<=0">

			<Timeline :value="mapList" align="alternate" 
            class="customized-timeline">
                <template #marker="slotProps">
               	 	<i class="iconfont icon-jian-tianchong"/>
                </template>
			
                <template #content="slotProps">
						<div class="card mb-0 text-center mapCard ">
						<h4 class="text-theme">
							{{slotProps.item.boss.name}}
						</h4>
						<div class="flex justify-content-between align-item-center mb-2">
							<div class="content">
								<p>
									所需战力
								</p>
								<h5>
									{{slotProps.item.boss.power.toNumber()}}
								</h5>
							</div>
							<div class="content">
								<p>
									所需体力值
								</p>
								<h5>
									{{slotProps.item.boss.consumePS.toNumber()}}
								</h5>
							</div>
							<div class="content">
								<p>
									可获经验
								</p>
								<h5>
									{{slotProps.item.boss.experience.toNumber()}}
								</h5>
							</div>
							
						</div>
						<img src="@/assets/img/map2.jpg" class="mb-1" />
						<div class="mapBgBox mb-2 flex">
							<div style="flex: 1;">
								<p>
									当前成功率
								</p>
								<h4>
									{{slotProps.item.curSuccessRate.toNumber()+"%"}}
								</h4>
							</div>
							<div  style="flex: 1;">
							<p>
								奖励数量
							</p>
							<h4>
							{{Number(ethers.utils.formatEther(slotProps.item.earnAmount.toString()))}}
							</h4>
							</div>
							<div  style="flex: 1;">
							<p>
								地图等级
							</p>
							<h4>
							{{slotProps.item.boss.level.toNumber()}}
							</h4>
							</div>

							
						</div>
						<div class="flex justify-content-between align-item-center ">
							<div class="content">
								<p>
									基础成功率
								</p>
								<h5>
									{{slotProps.item.boss.basicRate.toNumber()+'%'}}
								</h5>
							</div>
							<div class="content">
								<p>
									排名奖金
								</p>
								<h5>
									{{slotProps.item.rankBonus}}%
								</h5>
							</div>
							<div class="content">
								<p>
									等级奖金
								</p>
								<h5>
									{{slotProps.item.levelBonus}}%
								</h5>
							</div>
						</div>



					</div>
                   
                </template>
            </Timeline>

		</div>
		<template v-else-if="!$store.getters.getRoleListData[$store.getters.getChoseRoleId].canMine">
			<div class="grid mt-4 mb-4 justify-content-center">
				<div class="col-12 lg:col-6 xl:col-4">
					<div class="card mb-0 text-center mapCard  waitCard">
						<h6 class="text-danger">
							该角色当前不能探索
						</h6>
						<i class="iconfont icon-qizhi text-theme" />
						<p>
							每次探索完地图后，需要等待24小时，才能继续使用该角色
						</p>

						<p class="text-bold">

							{{new Date(moment($store.getters.getRoleListData[$store.getters.getChoseRoleId].role.bossChallengeTime*1000).format("YYYY-MM-DD HH:mm"))}}
						</p>

					</div>
				</div>
			</div>
			
			<Timeline :value="mapList" align="alternate" 
            class="customized-timeline">
                <template #marker="slotProps">
               	 	<i class="iconfont icon-jian-tianchong"/>
                </template>
			
                <template #content="slotProps">
						<div class="card mb-0 text-center mapCard ">
						<h4 class="text-theme">
							{{slotProps.item.boss.name}}
						</h4>
						<div class="flex justify-content-between align-item-center mb-2">
							<div class="content">
								<p>
									所需战力
								</p>
								<h5>
									{{slotProps.item.boss.power.toNumber()}}
								</h5>
							</div>
							<div class="content">
								<p>
									所需体力值
								</p>
								<h5>
									{{slotProps.item.boss.consumePS.toNumber()}}
								</h5>
							</div>
							<div class="content">
								<p>
									可获经验
								</p>
								<h5>
									{{slotProps.item.boss.experience.toNumber()}}
								</h5>
							</div>
							
						</div>
						<img src="@/assets/img/map2.jpg" class="mb-1" />
						<div class="mapBgBox mb-2 flex">
							<div style="flex: 1;">
								<p>
									当前成功率
								</p>
								<h4>
									{{slotProps.item.curSuccessRate.toNumber()+"%"}}
								</h4>
							</div>
							<div  style="flex: 1;">
							<p>
								奖励数量
							</p>
							<h4>
							{{Number(ethers.utils.formatEther(slotProps.item.earnAmount.toString()))}}
							</h4>
							</div>
							<div  style="flex: 1;">
							<p>
								地图等级
							</p>
							<h4>
							{{slotProps.item.boss.level.toNumber()}}

							</h4>
							</div>

						</div>
						<div class="flex justify-content-between align-item-center ">
							<div class="content">
								<p>
									基础成功率
								</p>
								<h5>
									{{slotProps.item.boss.basicRate.toNumber()+'%'}}
								</h5>
							</div>
							<div class="content">
								<p>
									排名奖金
								</p>
								<h5>
									{{slotProps.item.rankBonus}}%
								</h5>
							</div>
							<div class="content">
								<p>
									等级奖金
								</p>
								<h5>
									{{slotProps.item.levelBonus}}%
								</h5>
							</div>
						</div>



					</div>
                   
                </template>
            </Timeline>
		</template>
		<div class="grid mt-4" v-else>
			<Timeline :value="mapList" align="alternate" 
            	class="customized-timeline">
                <template #marker="slotProps">
               	 	<i class="iconfont icon-jian-tianchong"/>
                </template>
			
                <template #content="slotProps">
					<div class="card mb-0 text-center mapCard ">
						<h4 class="text-theme">
							{{slotProps.item.boss.name}}
						</h4>
						<div class="flex justify-content-between align-item-center mb-2">
							<div class="content">
								<p>
									所需战力
								</p>
								<h5>
									{{slotProps.item.boss.power.toNumber()}}
								</h5>
							</div>
							<div class="content">
								<p>
									所需体力值
								</p>
								<h5>
									{{slotProps.item.boss.consumePS.toNumber()}}
								</h5>
							</div>
							<div class="content">
								<p>
									可获经验
								</p>
								<h5>
									{{slotProps.item.boss.experience.toNumber()}}
								</h5>
							</div>
							
						</div>
						<img src="@/assets/img/map2.jpg" class="mb-1" />
						<div class="mapBgBox mb-2 flex">
							<div style="flex: 1;">
								<p>
									当前成功率
								</p>
								<h4>
									{{slotProps.item.curSuccessRate.toNumber()+"%"}}
								</h4>
							</div>
							<div  style="flex: 1;">
							<p>
								奖励数量
							</p>
							<h4>
							{{Number(ethers.utils.formatEther(slotProps.item.earnAmount.toString()))}}

							</h4>
							</div>
							<div  style="flex: 1;">
							<p>
								地图等级
							</p>
							<h4>
							{{slotProps.item.boss.level.toNumber()}}
							</h4>
							</div>
							
						</div>
						<div class="flex justify-content-between align-item-center mb-4">
							<div class="content">
								<p>
									基础成功率
								</p>
								<h5>
									{{slotProps.item.boss.basicRate.toNumber()+'%'}}
								</h5>
							</div>
							<div class="content">
								<p>
									排名奖金
								</p>
								<h5>
									{{slotProps.item.rankBonus}}%
								</h5>
							</div>
							<div class="content">
								<p>
									等级奖金
								</p>
								<h5>
									{{slotProps.item.levelBonus}}%
								</h5>
							</div>
						</div>
						<Button label="当前体力不足" class="p-button-raised p-button-yellow" disabled
							v-if="$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.ps.toNumber()<slotProps.item.boss.consumePS.toNumber()"></Button>
						<Button label="战力不足" class="p-button-raised p-button-yellow" disabled
							v-else-if="$store.getters.getRoleListData[$store.getters.getChoseRoleId].minePower.toNumber()<slotProps.item.boss.power.toNumber()"></Button>

						<Button label="当前宝石维护天数不足" class="p-button-raised p-button-yellow" disabled
							v-else-if="$store.getters.getRoleListData[$store.getters.getChoseRoleId].role.leaseExpireTime.toNumber()<1"></Button>
						
						<challengeBoss :roleInfo="$store.getters.getRoleListData[$store.getters.getChoseRoleId]" 
						@getMapList="getMapList"
						:bossLevel="slotProps.item.boss.level.toNumber()" v-else/>
						



					</div>
                   
                </template>
            </Timeline>
		
		</div>
	</template>

</template>
<style lang="scss">
	.roleList {
		height: max-content;

		.detail {
			padding: 5px;

			p {
				margin-bottom: 0;
			}
		}

		img {
			width: 100px;

			object-fit: cover;
			object-position: top;
		}

		&.active {
			border: 1px solid #FFF56D;
		}
	}

	.mapCard {
		.content {
			flex: 1;
			margin-bottom:0;
		}
		img{
			width: 100%;
			height: 20vh;
			object-fit: cover;
		}
	}
	.mapBgBox{
		background: $bg-color;
		padding:10px;
		p,h4{
			margin:0;
		}
	}
	.waitCard {
		i {
			font-size: 4rem;
			margin-bottom: 2rem;
			display: block;
		}
	}
	
</style>
<script>
	import {
		defineComponent,
		ref,
		computed,
		watch
	} from 'vue';
	import {
		useStore
	} from "vuex";
	import createRole from "../components/Create/role.vue";
	import {
		getRoleList
	} from "../wallet/LogicRole";
	import buyBtnModal from "../components/Modal/buyModal.vue";
	import selectRoleModal from "../components/Modal/selectRoleModal.vue";
	import moment from "moment";
	import Timeline from 'primevue/timeline'; 
	import challengeBoss from "../components/Modal/challengeBoss.vue"
	import {
		useRouter
	} from "vue-router";
	import * as logicBoss from "../wallet/LogicBoss";
	export default defineComponent({
		name: "mapPage",

		components: {
			createRole,
			buyBtnModal,
			selectRoleModal,
			Timeline,
			challengeBoss
		},
		setup() {
			const store = useStore();
			const router = useRouter()
			let RoleList = ref('');
			let active = ref(0);
			let roleInfo = ref(store.getters.getRoleListData[0]);
			let mapList = ref();
			let minPs = ref();
			let maxMap = ref();
			let maxCanMapLevel = ref(0);
			//获取地图
			const getMapList = (roleInfo) => {
				
				let roleId;
				console.log(roleInfo)
				if (roleInfo) {
				
					roleId = (roleInfo.role.itemId).toNumber()
				} else {
						roleId = 0;
				}
				console.log("更换角色信息",roleId)
				logicBoss.getMapList(roleId).then(res => {
				
					mapList.value = res;
					console.log("获取地图数据",res)
					let arrs = res.map((value, index, array) => {

						if (store.getters.getRoleListData.length > 0) {

							if ((store.getters.getRoleListData[store.getters.getChoseRoleId]
									.minePower).toNumber() >= value.boss.power.toNumber()) {
								maxCanMapLevel.value = value.boss.level;

							}

						}
						return true;
					})
					minPs.value = Math.max(...arrs);
					console.log("地图数据体力值", arrs)
				})
			}

			console.log("role角色的数组", store.getters.getRoleListData)
			if (!store.getters.getRoleListData) {
				store.dispatch('fetchRoleListData').then((res) => {
					console.log("actions挑取成功", res)
					if (res.length > 0) {

						RoleList.value = res;
						roleInfo.value = res[0];
						getMapList(roleInfo.value)
					} else {
						console.log("大地图")
						getMapList(0)
					}

				})
			} else {

				roleInfo.value = store.getters.getRoleListData[store.getters.getChoseRoleId];
				if (roleInfo.value) {
					getMapList(roleInfo.value)
				} else {
					getMapList(0)
				}

			}
			watch(() => store.getters.getChoseRoleId, () => {

				getMapList(store.getters.getRoleListData[store.getters.getChoseRoleId])
			})

			//console.log("测试获取当前的roleId",store.Store().getters.getChoseRoleId)
			//getMapList(roleInfo.value)


			const choseRole = (Info, index) => {
				active.value = index;
				mapList.value = '';
				roleInfo.value = Info;
				getMapList(Info)
			}

			//挑战boss
			const challengeBoss = (bossLevel) => {

				logicBoss.challengeBoss(bossLevel, (roleInfo.value.role.itemId).toNumber()).then(res => {
					console.log("获取打怪后的数据", res);
					//getRoleListEvent();
					store.dispatch('fetchRoleListData');
					getMapList(roleInfo.value);
				})
			}



			return {
				roleInfo,
				choseRole,
				getMapList,
				RoleList,
				//getRoleListEvent,

				active,
				mapList,
				challengeBoss,
				minPs,
				maxCanMapLevel,
				moment
			}
		}

	})
</script>